import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

var firebaseConfig = {
  apiKey: "AIzaSyBsKDq8yQ01VfP8pLFesUYbFSKEKeSSISw",
  authDomain: "photo-app-1e68c.firebaseapp.com",
  projectId: "photo-app-1e68c",
  storageBucket: "photo-app-1e68c.appspot.com",
  messagingSenderId: "214684682756",
  appId: "1:214684682756:web:1d1040681a0d7062dfc351"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, timestamp };