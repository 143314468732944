import React, { useState } from "react";
import Gallery from "./pages/gallery/Gallery";
import MainPage from "./pages/main-page/MainPage";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

const App: React.FC = () => {
  const [statusCodeChecked, setStatusCodeChecked] = useState<boolean>(false);

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route path="/home">
            <MainPage
              statusCodeChecked={statusCodeChecked}
              setStatusCodeChecked={setStatusCodeChecked}
            />
          </Route>
          <Route path="/year-1">
            <Gallery
              storageName={"year-1"}
              pageName={"1st Year"}
              year={"2018-2019"}
            />
          </Route>
          <Route path="/year-2">
            <Gallery
              storageName={"year-2"}
              pageName={"2nd Year"}
              year={"2019-2020"}
            />
          </Route>
          <Route path="/year-3">
            <Gallery
              storageName={"year-3"}
              pageName={"3rd Year"}
              year={"2020-2021"}
            />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
