import React, { useEffect } from "react";
import { motion } from "framer-motion";
import RightArrow from "../../assets/right-arrow.svg";
import LeftArrow from "../../assets/left-arrow.svg";

export interface ModalProps {
  images: any[];
  setSelectedImg: (e: any) => void;
  selectedImg: any;
  selectedImgIndex: any;
  setSelectedImgIndex: (e: any) => void;
}

const Modal: React.FC<ModalProps> = ({
  images,
  setSelectedImg,
  selectedImg,
  selectedImgIndex,
  setSelectedImgIndex,
}) => {
  const onExitClick = (e: any) => {
    if (e.target.classList.contains("backdrop")) {
      setSelectedImg(null);
      setSelectedImgIndex(null);
    }
  };

  const onEscapeClick = () => {
    setSelectedImg(null);
    setSelectedImgIndex(null);
  };

  const onNextButtonClick = () => {
    const newIndex = selectedImgIndex + 1;
    if (newIndex < images.length) {
      let newImage: any = images[newIndex];
      setSelectedImg(newImage.url);
      setSelectedImgIndex(newIndex);
    }
  };

  const onPreviousButtonClick = () => {
    const newIndex = selectedImgIndex - 1;
    if (newIndex >= 0) {
      let newImage: any = images[newIndex];
      setSelectedImg(newImage.url);
      setSelectedImgIndex(newIndex);
    }
  };

  useEffect(() => {
    const listener = (event: any) => {
      switch (event.code) {
        case "ArrowRight":
          event.preventDefault();
          onNextButtonClick();
          break;
        case "ArrowLeft":
          event.preventDefault();
          onPreviousButtonClick();
          break;
        case "Escape":
          event.preventDefault();
          onEscapeClick();
          break;
        default:
          break;
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  });

  return (
    <div>
      <motion.div
        className="backdrop"
        onClick={onExitClick}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <button className="arrow-button" onClick={onPreviousButtonClick}>
          <a className="text">random</a>
          <motion.img src={LeftArrow} whileHover={{ opacity: 1 }} />
        </button>
        <img src={selectedImg} alt="enlarged pic" />
        <button className="arrow-button" onClick={onNextButtonClick}>
          <a className="text">random</a>
          <motion.img src={RightArrow} whileHover={{ opacity: 1 }} />
        </button>
      </motion.div>
    </div>
  );
};

export default Modal;
