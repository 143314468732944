import React, { useEffect, useState } from "react";
import ImageGrid from "../../components/image-grid/ImageGrid";
import UploadForm from "../../components/upload-form/UploadForm";
import useFirestore from "../../hooks/useFirestore";
import { useHistory } from "react-router-dom";
import axios from "axios";
export interface MainPageProps {
  statusCodeChecked: boolean;
  setStatusCodeChecked: (update: boolean) => void;
}

const MainPage: React.FC<MainPageProps> = ({
  statusCodeChecked,
  setStatusCodeChecked,
}) => {
  const [selectedImg, setSelectedImg] = useState<any>(null);
  const { docs } = useFirestore("mainPage");
  const [statusCode, setStatusCode] = useState<any>(200);

  const history = useHistory();

  const onClickRouteChange = () => {
    if (selectedImg) {
      const selectedImgString = JSON.stringify(selectedImg).toLowerCase();
      let path;
      if (selectedImgString.includes("year-1")) {
        path = "/year-1";
      } else if (selectedImgString.includes("year-2")) {
        path = "/year-2";
      } else {
        path = "/year-3";
      }
      history.push(path);
    }
  };

  useEffect(() => {
    onClickRouteChange();
  }, [selectedImg]);

  return (
    <div className="App">
      <nav className="navbar">
        <h1>Happy Anniversary!</h1>
      </nav>
      <div className="title">
        <h2 className="bottom-margin">Click on a Pic!</h2>
      </div>
      {/* <UploadForm storageName={"mainPage"} /> */}
      <ImageGrid docs={docs} setSelectedImg={setSelectedImg} />
    </div>
  );
};

export default MainPage;
