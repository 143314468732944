import React, { useEffect, useState } from "react";
import UploadForm from "../../components/upload-form/UploadForm";
import ImageGrid from "../../components/image-grid/ImageGrid";
import Modal from "../../components/modal/Modal";
import useFirestore from "../../hooks/useFirestore";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import LeftArrow from "../../assets/left-arrow.svg";

export interface GalleryProps {
  storageName: string;
  pageName: string;
  year: string;
}

const Gallery: React.FC<GalleryProps> = ({ storageName, pageName, year }) => {
  const [selectedImg, setSelectedImg] = useState<any>(null);
  const [selectedImgIndex, setSelectedImgIndex] = useState<number | null>(null);
  const { docs } = useFirestore(storageName);

  const history = useHistory();

  const onClickRouteChange = () => {
    history.push("/home");
  };

  useEffect(() => {
    const index = docs.findIndex((e) => e.url === selectedImg);
    if (index !== -1) {
      setSelectedImgIndex(index);
    }
  }, [selectedImg]);

  return (
    <div className="App">
      <div className="nav-arrow">
        <button className="arrow-button back" onClick={onClickRouteChange}>
          <a className="text">abc</a>
          <motion.img src={LeftArrow} whileHover={{ opacity: 1 }} />
        </button>
        <nav className="navbar">
          <h1>Happy Anniversary!</h1>
        </nav>
      </div>

      <div className="title">
        <h2>{pageName}</h2>
        <h3 className="bottom-margin">{year}</h3>
      </div>
      {/* <UploadForm storageName={storageName} /> */}
      <ImageGrid docs={docs} setSelectedImg={setSelectedImg} />
      {selectedImg && (
        <Modal
          images={docs}
          selectedImg={selectedImg}
          setSelectedImg={setSelectedImg}
          selectedImgIndex={selectedImgIndex}
          setSelectedImgIndex={setSelectedImgIndex}
        />
      )}
      <br />
      <br />
    </div>
  );
};

export default Gallery;
